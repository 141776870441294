import React from "react";
import { ButtonStyled } from "./styles";

const Button = ({
  onClick,
  children,
  disabled,
  button_type,
  desktop_width,
  font_size,
  padding,
  margin,
  text_transform,
  background_color,
  mobile_display,
  desktop_display,
  bold,
  type,
  mobile_margin,
  tablet_width,
  mobile_width,
  id,
  hide_button,
  hover,
  height,
  disabled_color,
  disabled_background,
  disabled_shadow,
  box_shadow,
  border_radius,
}) => {
  return (
    <ButtonStyled
      id={id}
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      button_type={button_type}
      desktop_width={desktop_width}
      font_size={font_size}
      padding={padding}
      margin={margin}
      text_transform={text_transform}
      background_color={background_color}
      mobile_display={mobile_display}
      desktop_display={desktop_display}
      bold={bold}
      type={type}
      mobile_margin={mobile_margin}
      tablet_width={tablet_width}
      mobile_width={mobile_width}
      hide_button={hide_button}
      hover={hover ? "true" : "false"}
      height={height}
      disabled_color={disabled_color}
      disabled_background={disabled_background}
      disabled_shadow={disabled_shadow}
      box_shadow={box_shadow}
      border_radius={border_radius}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
