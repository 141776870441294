import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled, { css } from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import { styled as styling } from "@mui/styles";

export const ButtonStyled = withTheme(styled(Button)`
  && {
    ${(props) =>
      props.button_type == "PurpleGradient" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: ${props.border_radius ? props.border_radius : "30px"};
        font-size: ${props.font_size
          ? props.font_size
          : props.font_size == "caption"
          ? FontSize.desktopCaption
          : FontSize.desktopBody};
        font-weight: ${props.bold
          ? props.bold
          : props.bold == "false"
          ? "unset"
          : FontSize.bold};
        background: ${props.background_color
          ? props.background_color
          : Color.purpleGradient};
        color: ${Color.white};
        padding: ${props.padding?.includes("%")
          ? `${props.padding}`
          : typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        align-self: center;
        margin: ${typeof props.margin == "string" && `${props.margin}`};
        height: ${props.height ? props.height : "50px"};
        box-shadow: ${props.box_shadow && props.box_shadow};

        ${(props) => props.theme.breakpoints.down("tabletS")} {
          min-width: ${props.tablet_width && props.tablet_width};
        }

        ${(props) => props.theme.breakpoints.down("mobileL")} {
          min-width: ${props.mobile_width && props.mobile_width};
        }

        &:hover {
          box-shadow: 0px 3px 16px #00000029;
        }

        &:hover div {
          &.MuiCircularProgress-root {
            color: white !important;
          }
        }

        ${(props) =>
          props.disabled &&
          css`
            background: ${props.disabled_background
              ? props.disabled_background
              : Color.secondaryGrey} !important;
            color: ${props.disabled_color
              ? props.disabled_color
              : `${Color.darkGrey}`} !important;
            box-shadow: ${props.disabled_shadow
              ? props.disabled_shadow
              : null} !important;
          `}
      `}

      ${(props) =>
        props.button_type == "Green" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: ${props.border_radius ? props.border_radius : "30px"};
          font-size: ${props.font_size
            ? props.font_size
            : props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.buttonGreen};
          color: ${Color.white};
          padding: ${props.padding?.includes("%")
            ? `${props.padding}`
            : typeof props.padding == "string"
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          align-self: center;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          box-shadow: 0px 3px 12px #cac9c8;

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
          }

          &:hover {
            box-shadow: 1px 1px 9px #cac9c8;
            background: ${Color.buttonHoverGreen};
          }

          &:hover div {
            &.MuiCircularProgress-root {
              color: white !important;
            }
          }

          ${(props) =>
            props.disabled &&
            css`
              background-color: ${Color.secondaryGrey} !important;
              color: ${Color.darkGrey};
            `}
        `}

    ${(props) =>
      props.button_type == "SolidPurple" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${props.font_size
          ? props.font_size
          : props.font_size == "caption"
          ? FontSize.desktopCaption
          : FontSize.desktopBody};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.hiredlyPurple};
        color: ${Color.white};
        padding: ${props.padding?.includes("%")
          ? `${props.padding}`
          : typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        align-self: center;
        margin: ${typeof props.margin == "string" && `${props.margin}`};

        ${(props) => props.theme.breakpoints.down("tabletS")} {
          min-width: ${props.tablet_width && props.tablet_width};
        }

        ${(props) => props.theme.breakpoints.down("mobileL")} {
          min-width: ${props.mobile_width && props.mobile_width};
        }

        &:hover {
          box-shadow: 0px 3px 16px #00000029;
          background: #3a12b9 0% 0% no-repeat padding-box;
        }

        &:hover div {
          &.MuiCircularProgress-root {
            color: white !important;
          }
        }

        ${(props) =>
          props.disabled &&
          css`
            background-color: ${Color.secondaryGrey} !important;
            color: ${Color.darkGrey};
          `}
      `}

    ${(props) =>
      props.button_type == "SolidBlack" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${FontSize.desktopCaption};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.black};
        color: ${Color.white};
        padding: ${typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        border: 1px solid ${Color.black};
        margin: ${typeof props.margin == "string" && `${props.margin}`};
        box-shadow: none;

        &: hover {
          background: ${Color.black};
          color: ${Color.white};
          box-shadow: none;
        }
      `}


    ${(props) =>
      props.button_type == "OutlinedBlack" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${FontSize.desktopCaption};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.white};
        padding: ${typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        border: 1px solid ${Color.black};
        margin: ${typeof props.margin == "string" && `${props.margin}`};
        box-shadow: none;
        display: ${props.hide_button == "true" ? "none" : "inherit"};
        color: ${Color.black};

        &: hover {
          background: ${Color.white};
          box-shadow: none;
        }
      `}

    ${(props) =>
      props.button_type == "OutlinedWhite" &&
      css`
        margin-right: 10px;
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${props.font_size
          ? props.font_size
          : props.font_size == "caption"
          ? FontSize.desktopCaption
          : FontSize.desktopBody};
        color: ${Color.white};
        background: transparent;
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        padding: ${typeof props.padding == "string"
          ? `${props.padding}`
          : "8px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        border: 2px solid ${Color.white};
        align-self: center;
        box-shadow: none;

        &: hover {
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
        }

        ${(props) =>
          props.disabled &&
          css`
            background-color: ${Color.grey};
            color: ${Color.darkGrey};
          `}
      `}

      ${(props) =>
        props.button_type == "SolidGrey" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${FontSize.desktopCaption};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          color: ${Color.black};
          background: ${props.background_color == "none"
            ? "unset"
            : Color.grey};
          border: none;
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          display: ${props.desktop_display == "none" && "none"};

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
            display: ${props.mobile_display == "none" ? "none" : "block"};
          }

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          &: hover {
            background-color: ${Color.grey};
            box-shadow: none;
          }
        `}

      ${(props) =>
        props.button_type == "OutlinedIndigo" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size
            ? props.font_size
            : props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          border: 1px solid ${Color.hiredlyPurple};
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          visibility: ${props.hide_button == "true" ? "hidden" : "visible"};

          &:hover {
            background: ${Color.lightGrey} 0% 0% no-repeat padding-box;
            box-shadow: none;
          }

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
            margin: ${typeof props.mobile_margin == "string" &&
            `${props.mobile_margin}`};
          }
        `}
      
      ${(props) =>
        props.button_type == "IndigoBold" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
        `}


      ${(props) =>
        props.button_type == "SolidWhite" &&
        css`
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
        `}

      ${(props) =>
        props.button_type == "OutlinedTransparentIndigo" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: transparent;
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          border: 1px solid ${Color.hiredlyPurple};
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          visibility: ${props.hide_button == "true" ? "hidden" : "visible"};

          &:hover {
            background: ${Color.hiredlyPurple};
            color: ${Color.white};
          }

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
            margin: ${typeof props.mobile_margin == "string" &&
            `${props.mobile_margin}`};
          }
        `}

        ${(props) =>
          props.button_type == "TransparentGrey" &&
          css`
            min-width: ${props.desktop_width == "Long"
              ? "200px"
              : props.desktop_width == "Medium"
              ? "150px"
              : props.desktop_width == "Short"
              ? "120px"
              : props.desktop_width?.includes("%")
              ? `${props.desktop_width}`
              : typeof props.desktop_width == "string"
              ? `${props.desktop_width}`
              : "30px"};
            border-radius: 30px;
            font-size: ${props.font_size == "caption"
              ? FontSize.desktopCaption
              : FontSize.desktopBody};
            font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
            background: transparent;
            color: ${Color.darkGrey};
            padding: ${typeof props.padding == "string"
              ? `${props.padding}`
              : props.padding?.includes("%")
              ? `${props.padding}`
              : "10px 20px"};
            text-transform: ${props.text_transform == "capitalize"
              ? "capitalize"
              : props.text_transform == "uppercase"
              ? "uppercase"
              : "none"};
            border: 1px solid ${Color.white};
            box-shadow: none;
            margin: ${typeof props.margin == "string" && `${props.margin}`};
            visibility: ${props.hide_button == "true" ? "hidden" : "visible"};

            &:hover {
              background: ${Color.white};
              color: ${Color.darkGrey};
            }

            ${(props) => props.theme.breakpoints.down("tabletS")} {
              min-width: ${props.tablet_width && props.tablet_width};
            }

            ${(props) => props.theme.breakpoints.down("mobileL")} {
              min-width: ${props.mobile_width && props.mobile_width};
              margin: ${typeof props.mobile_margin == "string" &&
              `${props.mobile_margin}`};
            }
          `}
`);

export const LoadingButtonStyled = withTheme(styled(LoadingButton)`
  && {
    ${(props) =>
      props.button_type == "PurpleGradient" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${props.font_size == "caption"
          ? FontSize.desktopCaption
          : FontSize.desktopBody};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.purpleGradient};
        color: ${Color.white};
        padding: ${props.padding?.includes("%")
          ? `${props.padding}`
          : typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        align-self: center;
        margin: ${typeof props.margin == "string" && `${props.margin}`};
        height: 50px;

        ${(props) => props.theme.breakpoints.down("tabletS")} {
          min-width: ${props.tablet_width && props.tablet_width};
        }

        ${(props) => props.theme.breakpoints.down("mobileL")} {
          min-width: ${props.mobile_width && props.mobile_width};
        }

        &:hover {
          box-shadow: 0px 3px 16px #00000029;
        }

        &:hover div {
          &.MuiCircularProgress-root {
            color: white !important;
          }
        }

        ${(props) =>
          props.disabled &&
          css`
            background-color: ${Color.secondaryGrey} !important;
            color: ${Color.darkGrey};
          `}
      `}

    ${(props) =>
      props.button_type == "SolidPurple" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${props.font_size == "caption"
          ? FontSize.desktopCaption
          : FontSize.desktopBody};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.hiredlyPurple};
        color: ${Color.white};
        padding: ${props.padding?.includes("%")
          ? `${props.padding}`
          : typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        align-self: center;
        margin: ${typeof props.margin == "string" && `${props.margin}`};

        ${(props) => props.theme.breakpoints.down("tabletS")} {
          min-width: ${props.tablet_width && props.tablet_width};
        }

        ${(props) => props.theme.breakpoints.down("mobileL")} {
          min-width: ${props.mobile_width && props.mobile_width};
        }

        &:hover {
          box-shadow: 0px 3px 16px #00000029;
          background: #3a12b9 0% 0% no-repeat padding-box;
        }

        &:hover div {
          &.MuiCircularProgress-root {
            color: white !important;
          }
        }

        ${(props) =>
          props.disabled &&
          css`
            background-color: ${Color.secondaryGrey} !important;
            color: ${Color.darkGrey};
          `}
      `}

    ${(props) =>
      props.button_type == "SolidBlack" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${FontSize.desktopCaption};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.black};
        color: ${Color.white};
        padding: ${typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        border: 1px solid ${Color.black};
        margin: ${typeof props.margin == "string" && `${props.margin}`};
        box-shadow: none;

        &: hover {
          background: ${Color.black};
          color: ${Color.white};
          box-shadow: none;
        }
      `}


    ${(props) =>
      props.button_type == "OutlinedBlack" &&
      css`
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${FontSize.desktopCaption};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        background: ${Color.white};
        padding: ${typeof props.padding == "string"
          ? `${props.padding}`
          : "10px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        border: 1px solid ${Color.black};
        margin: ${typeof props.margin == "string" && `${props.margin}`};
        box-shadow: none;
        display: ${props.hide_button == "true" ? "none" : "inherit"};
        color: ${Color.black};

        &: hover {
          background: ${Color.white};
          box-shadow: none;
        }
      `}

    ${(props) =>
      props.button_type == "OutlinedWhite" &&
      css`
        margin-right: 10px;
        min-width: ${props.desktop_width == "Long"
          ? "200px"
          : props.desktop_width == "Medium"
          ? "150px"
          : props.desktop_width == "Short"
          ? "120px"
          : props.desktop_width?.includes("%")
          ? `${props.desktop_width}`
          : typeof props.desktop_width == "string"
          ? `${props.desktop_width}`
          : "150px"};
        border-radius: 30px;
        font-size: ${props.font_size == "caption"
          ? FontSize.desktopCaption
          : FontSize.desktopBody};
        color: ${Color.white};
        background: transparent;
        font-size: ${FontSize.desktopBody};
        font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
        padding: ${typeof props.padding == "string"
          ? `${props.padding}`
          : "8px 20px"};
        text-transform: ${props.text_transform == "capitalize"
          ? "capitalize"
          : props.text_transform == "uppercase"
          ? "uppercase"
          : "none"};
        border: 2px solid ${Color.white};
        align-self: center;
        box-shadow: none;

        &: hover {
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
        }

        ${(props) =>
          props.disabled &&
          css`
            background-color: ${Color.grey};
            color: ${Color.darkGrey};
          `}
      `}

      ${(props) =>
        props.button_type == "SolidGrey" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${FontSize.desktopCaption};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          color: ${Color.black};
          background: ${props.background_color == "none"
            ? "unset"
            : Color.grey};
          border: none;
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          display: ${props.desktop_display == "none" && "none"};

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
            display: ${props.mobile_display == "none" ? "none" : "block"};
          }

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          &: hover {
            background-color: ${Color.grey};
            box-shadow: none;
          }
        `}

      ${(props) =>
        props.button_type == "OutlinedIndigo" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          border: 1px solid ${Color.hiredlyPurple};
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          visibility: ${props.hide_button == "true" ? "hidden" : "visible"};

          &:hover {
            background: ${Color.lightGrey} 0% 0% no-repeat padding-box;
            box-shadow: none;
          }

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
            margin: ${typeof props.mobile_margin == "string" &&
            `${props.mobile_margin}`};
          }
        `}
      
      ${(props) =>
        props.button_type == "IndigoBold" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
        `}


      ${(props) =>
        props.button_type == "SolidWhite" &&
        css`
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: ${Color.white};
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
        `}

      ${(props) =>
        props.button_type == "OutlinedTransparentIndigo" &&
        css`
          min-width: ${props.desktop_width == "Long"
            ? "200px"
            : props.desktop_width == "Medium"
            ? "150px"
            : props.desktop_width == "Short"
            ? "120px"
            : props.desktop_width?.includes("%")
            ? `${props.desktop_width}`
            : typeof props.desktop_width == "string"
            ? `${props.desktop_width}`
            : "150px"};
          border-radius: 30px;
          font-size: ${props.font_size == "caption"
            ? FontSize.desktopCaption
            : FontSize.desktopBody};
          font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
          background: transparent;
          color: ${Color.hiredlyPurple};
          padding: ${typeof props.padding == "string"
            ? `${props.padding}`
            : props.padding?.includes("%")
            ? `${props.padding}`
            : "10px 20px"};
          text-transform: ${props.text_transform == "capitalize"
            ? "capitalize"
            : props.text_transform == "uppercase"
            ? "uppercase"
            : "none"};
          border: 1px solid ${Color.hiredlyPurple};
          box-shadow: none;
          margin: ${typeof props.margin == "string" && `${props.margin}`};
          visibility: ${props.hide_button == "true" ? "hidden" : "visible"};

          &:hover {
            background: ${Color.hiredlyPurple};
            color: ${Color.white};
          }

          ${(props) => props.theme.breakpoints.down("tabletS")} {
            min-width: ${props.tablet_width && props.tablet_width};
          }

          ${(props) => props.theme.breakpoints.down("mobileL")} {
            min-width: ${props.mobile_width && props.mobile_width};
            margin: ${typeof props.mobile_margin == "string" &&
            `${props.mobile_margin}`};
          }
        `}

        ${(props) =>
          props.button_type == "TransparentGrey" &&
          css`
            min-width: ${props.desktop_width == "Long"
              ? "200px"
              : props.desktop_width == "Medium"
              ? "150px"
              : props.desktop_width == "Short"
              ? "120px"
              : props.desktop_width?.includes("%")
              ? `${props.desktop_width}`
              : typeof props.desktop_width == "string"
              ? `${props.desktop_width}`
              : "30px"};
            border-radius: 30px;
            font-size: ${props.font_size == "caption"
              ? FontSize.desktopCaption
              : FontSize.desktopBody};
            font-weight: ${props.bold == "false" ? "unset" : FontSize.bold};
            background: transparent;
            color: ${Color.darkGrey};
            padding: ${typeof props.padding == "string"
              ? `${props.padding}`
              : props.padding?.includes("%")
              ? `${props.padding}`
              : "10px 20px"};
            text-transform: ${props.text_transform == "capitalize"
              ? "capitalize"
              : props.text_transform == "uppercase"
              ? "uppercase"
              : "none"};
            border: 1px solid ${Color.white};
            box-shadow: none;
            margin: ${typeof props.margin == "string" && `${props.margin}`};
            visibility: ${props.hide_button == "true" ? "hidden" : "visible"};

            &:hover {
              background: ${Color.white};
              color: ${Color.darkGrey};
            }

            ${(props) => props.theme.breakpoints.down("tabletS")} {
              min-width: ${props.tablet_width && props.tablet_width};
            }

            ${(props) => props.theme.breakpoints.down("mobileL")} {
              min-width: ${props.mobile_width && props.mobile_width};
              margin: ${typeof props.mobile_margin == "string" &&
              `${props.mobile_margin}`};
            }
          `}
`);

export const LoadingIconStyled = styling(CircularProgress)({
  color: Color.white,
});
